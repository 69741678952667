module.exports = {
  siteTitle: 'Barcamp - NextWeb', // <title>
  manifestName: 'Barcamp',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'minimal-ui',
  manifestIcon: 'src/assets/images/favicons/favicon-160x160.png',
  heading: 'Barcamp - Nextweb',
  subHeading: 'Barcamp at FH Salzburg',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-meetup',
      name: 'Meetup',
      url: 'https://www.meetup.com/en-AU/salzburgwebdev',
    },
    {
      style: 'brands',
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/salzburgwebdev',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:brigitte.jellinek@fh-salzburg.ac.at',
    },
  ],
};
