import React, { useState } from 'react';
import Nav from './nav';
import { Link } from 'gatsby';
import logo from '../assets/images/barcamp_logo.svg';

export default ({ fullMenu }) => {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
      <h1>
        <Link to="/">
          <img className="menu-image" src={logo} alt="logo" />
        </Link>
      </h1>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </header>
  );
};
